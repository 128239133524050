.nav-icon {
    svg {
        margin-right: 0.25rem;
    }
}

.sidebar-dark {
    .nav-item {
        .nav-link {
            &.active {
                font-weight: 500;
                color: @white;
            }
        }
    }
}

@media (min-width: 991px) {
    .sidebar-dark {
        .nav-item {
            .nav-link {
                padding: 0.3rem 0.75rem !important;
            }
        }
    }
}

@media (min-width: 768px) {
    .sidebar-dark {
        .nav-item {
            .nav-link {
                padding: 0.5rem 0.75rem;
            }
        }
    }
}
