@media (max-width: 576px) {
    .btn-xs-primary {
        color: @white;
        background-color: @mainColor;
        border-color: @mainColor;
        border-radius: 0;
        padding: 0.25rem;
    }

    .list-categories {
        .list-inline-item {
            margin: 0.15rem 0;
            display: block;
        }
    }

    .card-header {
        padding: 0.5rem 0.75rem !important;
    }

    .loader-mounted {
        .loader-ring {
            width: 80px;
            height: 90px;
        }
    }

    .loader-ring {
        width: 100px;
        height: 100px;

        &.xl {
            div {
                width: 80px;
                height: 80px;
            }
        }
    }

    .p-5 {
        padding: 1.25rem !important;
    }

    .sidebar {
        width: 3rem;

        .sidebar-heading {
            display: none;
            padding: 0 0.2rem;
        }

        hr.sidebar-divider {
            margin: 0;
        }

        .nav-item {
            .nav-link {
                width: 100%;
                padding: 0.10rem;
                margin: 0.15rem 0;

                span {
                    display: none;
                }
            }
        }
    }

    .row-header {
        display: none;
    }

    .row-element {
        border-top: 3px solid @grayDark;
        margin-top: 0 !important;

        [class^="col-"] {
            padding: 0.25rem 0;
            border-bottom: 1px solid @grayDark;
        }

        .d-sm-none {
            font-weight: bold;
        }

        .is-status {
            text-align: center;
        }
    }

    .toolbar-actions {
        top: 4px;
    }

    #boardgame-selectFindGame {
        height: 80px;
    }
}
