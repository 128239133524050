label {
    cursor: pointer;
}

.react-mde.is-invalid {
    border-color: @danger;
}

.react-mde.is-invalid ~ .invalid-feedback {
    display: block;
}

.spinner-grow {
    vertical-align: bottom;
}

#boardgame-selectFindGame {
    height: 250px;
}
