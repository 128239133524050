.row {
    &.row-header {
        font-size: 1.25rem;
        margin-top: 4px;
        margin-bottom: 6px;
        color: @white;
        background-color: @grayDark;

        [class^='col-'] {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
}

.card {
    &.shortcut {
        &:hover {
            text-decoration: none !important;

            .h5 {
                color: @grayDark !important;
            }

            div[class^='text-'] {
                color: @white !important;
            }
        }

        &.primary {
            &:hover {
                color: @white !important;
                background-color: @mainColor;

                .h5 {
                    color: @grayLight !important;
                }
            }
        }

        &.secondary {
            &:hover {
                color: @white !important;
                background-color: @secondary;

                .h5 {
                    color: @grayLight !important;
                }
            }
        }

        &.tertiary {
            &:hover {
                color: @white !important;
                background-color: @tertiary;

                .h5 {
                    color: @grayLight !important;
                }
            }
        }

        &.success {
            &:hover {
                color: @white !important;
                background-color: @success;
            }
        }

        &.info {
            &:hover {
                color: @white !important;
                background-color: @info;
            }
        }

        &.warning {
            &:hover {
                color: @white !important;
                background-color: @warning;
            }
        }

        &.danger {
            &:hover {
                color: @white !important;
                background-color: @danger;
            }
        }
    }

    .dropdown {
        .dropdown-toggle {
            padding: 0;

            &:after {
                display: none;
            }
        }
    }
}

.btn {
    line-height: 1 !important;

    &.btn-xs {
        padding: 0.15rem 0.25rem;
        font-size: 0.75rem;
        line-height: 1.35;
        border-radius: 0.15rem;
    }

    &.btn-inverted {
        &.btn-primary {
            &:hover {
                color: @mainColor;
                background-color: @white;
                border-color: @mainColor;
            }
        }

        &.btn-warning {
            &:hover {
                color: @warning;
                background-color: @white;
                border-color: @warning;
            }
        }

        &.btn-danger {
            &:hover {
                color: @danger;
                background-color: @white;
                border-color: @danger;
            }
        }

        &.btn-info {
            &:hover {
                color: @info;
                background-color: @white;
                border-color: @info;
            }
        }
    }
}

.toolbar-actions {
    position: absolute;
    z-index: -1;
    opacity: 0;
    top: 0;
    left: -20px;
    transition: opacity 0.75s, left 0.55s;

    &.open {
        z-index: 2;
        left: 50px;
        opacity: 1;
    }
}

.modal {
    display: block;
    opacity: 0;
    left: -9999px;

    &.show {
        left: 0;
        opacity: 1;
        background-color: @overlay;
    }
}

.cp {
    cursor: pointer;
}

.rounded-circle {
    color: @white;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    margin-bottom: 1rem;
    background-color: rgba(255, 255, 255, 0.2);
}

.dropdown-toggle {
    color: @gray !important;
    background: transparent;
    border: none;
}

.react-hooks-paginator {
    padding: 0;
}
