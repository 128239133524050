@import 'themes/sb-admin/style';
@import 'loader';
@import 'form';
@import 'misc';
@import 'menu';
@import 'responsive';

.rdt_TableHeader {
    display: none !important;
}
