.loader-wrapper {
    background-color: @tertiary;
    display: none;
    left: 0.75rem;
    right: 0.75rem;

    &.loading {
        display: block;
    }
}

.loader-mounted {
    background-color: @secondary;
    left: 0;
    right: 0;
}

.loader-default {
    background-color: @mainColor;
}

.loader-wrapper,
.loader-mounted,
.loader-default {
    position: absolute;
    z-index: 10;
    bottom: 0;
    top: 0;
    transition: all 3.75s;
}

.loader-mounted {
    .loader-ring {
        width: 90px;
        height: 90px;
    }
}

.loader-ring {
    position: absolute;
    z-index: 10;
    display: inline-block;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 160px;
    height: 160px;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 70px;
        height: 70px;
        margin: 8px;
        border-width: 8px;
        border-style: solid;
        border-color: @white transparent transparent transparent;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;
        }

        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }

    &.xl div {
        width: 120px;
        height: 120px;
    }
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
